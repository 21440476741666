<script>
import { layoutMethods, layoutComputed } from "@/state/helpers";
import {mapGetters} from "vuex";

/**
 * Horizontal-navbar component
 */
export default {
  props: {
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
      ...mapGetters({
          availableReceive: 'auth/availableReceive',
      }),
      ...layoutComputed,

      menuItems: function () {
        const menu = [
            {
                id: 1,
                label: 'menuitems.contact_lists',
                icon: 'users',
                link: this.$t('routes.contact_lists'),
            },
            {
                id: 2,
                label: 'menuitems.send_sms',
                icon: 'send',
                link: this.$t('routes.send_sms'),
            },
            {
                id: 3,
                label: 'menuitems.sent_sms',
                icon: 'download-cloud',
                link: this.$t('routes.sent_sms'),
            },
            {
                id: 5,
                label: 'menuitems.api_accounts',
                icon: 'terminal',
                link: this.$t('routes.api_accounts'),
            },
            {
                id: 6,
                label: 'menuitems.prices',
                icon: 'dollar-sign',
                link: this.$t('routes.prices'),
            },
        ];

          if (this.availableReceive) {
              menu.push({
                  id: 4,
                  label: 'menuitems.received_sms',
                  icon: 'upload-cloud',
                  link: this.$t('routes.received_sms'),
              })
          }

          menu.sort((a,b) => {
              return a.id === b.id ? 0 : (a.id < b.id ? -1 : 1)
          })

          return menu
      },
  },
  mounted() {
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.add("active");
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add("active");
                const parent6 = parent5.parentElement;
                if (parent6) {
                  parent6.classList.add("active");
                }
              }
            }
          }
        }
      }
    }

    // this.fakeFetchData();
  },
  watch: {
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-size", "fluid");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-size", "fluid");
              break;
          }
        }
      },
    },
  },
  methods: {
    ...layoutMethods,
    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling;
      if (nextEl && !nextEl.classList.contains("show")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.add("show");
      } else if (nextEl) {
        nextEl.classList.remove("show");
      }
      return false;
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    hasManyItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 8 : false;
    },
  },
};
</script>

<template>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">
             <template v-for="(item, index) of menuItems">
              <li class="nav-item dropdown" :key="index">
                <router-link
                  class="nav-link dropdown-toggle arrow-none"
                  v-if="!item.subItems"
                  id="topnav-components"
                  :to="item.link"
                  role="button"
                >
                  <feather :type="`${item.icon}`"></feather>{{ $t(item.label) }}
                  <div class="arrow-down" v-if="hasItems(item)"></div>
                </router-link>

                <a
                  v-if="item.subItems"
                  class="nav-link dropdown-toggle arrow-none"
                  @click="onMenuClick($event)"
                  id="topnav-components"
                  role="button"
                >
                  <feather :type="`${item.icon}`"></feather>
                  {{ $t(item.label) }}
                  <div class="arrow-down"></div>
                </a>

                <div
                  class="dropdown-menu"
                  aria-labelledby="topnav-dashboard"
                  v-if="hasItems(item)"
                >
                  <template v-for="(subitem, index) of item.subItems">
                    <router-link
                      class="col dropdown-item side-nav-link-ref"
                      :key="index"
                      v-if="!hasItems(subitem)"
                      :to="subitem.link"
                      >{{ $t(subitem.label) }}</router-link
                    >

                    <div class="dropdown" v-if="hasItems(subitem)" :key="index">
                      <a
                        class="dropdown-item dropdown-toggle"
                        href="javascript: void(0);"
                        @click="onMenuClick($event)"
                        >{{ $t(subitem.label) }}
                        <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu">
                        <template
                          v-for="(subSubitem, index) of subitem.subItems"
                        >
                          <router-link
                            class="dropdown-item side-nav-link-ref"
                            :key="index"
                            v-if="!hasItems(subSubitem)"
                            :to="subSubitem.link"
                            >{{ $t(subSubitem.label) }}</router-link
                          >
                          <div
                            class="dropdown"
                            v-if="hasItems(subSubitem)"
                            :key="index"
                          >
                            <a
                              class="dropdown-item dropdown-toggle"
                              href="javascript: void(0);"
                              @click="onMenuClick($event)"
                              >{{ $t(subSubitem.label) }}
                              <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu">
                              <template
                                v-for="(
                                  subSubSubitem, index
                                ) of subSubitem.subItems"
                              >
                                <router-link
                                  class="dropdown-item side-nav-link-ref"
                                  :key="index"
                                  :to="subSubSubitem.link"
                                  routerLinkActive="active"
                                  >{{ $t(subSubSubitem.label) }}</router-link
                                >
                              </template>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
