<script>
export default {

}
</script>

<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    {{new Date().getFullYear()}} &copy; SimpleSMS.
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-end d-none d-sm-block">
                        {{ $t('login.crafted_with') }} <i class="mdi mdi-heart text-danger"></i> <a href="https://Pichforest.com/" target="_blank" class="text-reset">APPON.hu</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
